@use  "variables" as *;
@use  "configs/context-menu" as *;
@use  "configs/icon-button" as *;
@use "table-toolbar-mixin" as *;
@use 'sass:map';

@mixin icon($color: null, $size: $global-icon-size) {
  $icon-size: $size;

  i, .dx-icon {
    @if $color {
      color: $color !important;
      fill: $color !important;
    }

    text-align: center;
    font-size: $icon-size;
    line-height: $icon-size;
  }

  .dx-icon, img, svg {
    height: $icon-size;
    width: $icon-size;
  }
}

@mixin icon_button($size, $color: null) {
  $config: get_icon_button_config($size);
  $widget-size: map.get($config, 'widget-size');
  $icon-size: map.get($config, 'icon-size');
  $icon-wrapper-padding: map.get($config, 'icon-wrapper-padding');

  height: $widget-size !important;
  min-width: $widget-size;

  .dx-button-content {
    padding: $icon-wrapper-padding;
  }

  @include icon($color, $icon-size);
}

@mixin icon_operation_widget_button($size, $color: null) {
  $widget-size: 14px;
  $icon-size: 10px;
  $icon-wrapper-padding: 1px;

  height: $widget-size !important;
  min-width: $widget-size;

  .dx-button-content {
    padding: $icon-wrapper-padding;
  }

  @include icon($color, $icon-size);
}


@mixin button-settings() {
  @include toolbar-icon();
  min-width: 16px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin toolbar-hidden-menu-text($config) {
  margin-left: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: $gray-1;
  text-transform: none;

  //right-arrow у випадаючому меню
  &.custom-dropdown-caret i {
    height: 16px;
    width: auto;
    font-size: 14px;
    line-height: 16px;
    margin-right: 12px;
  }
}

@mixin context-menu($size) {
  $config: cm_config();

  .dx-context-menu {
    border-radius: 5px !important;

    .dx-submenu {
      border-radius: 5px !important;

      &.toolbar-dropdown-context-menu {
        height: auto !important;
      }

      .dx-menu-items-container {
        padding: 0 !important;
      }

      .dx-item-content.dx-menu-item-content {
        display: flex;
        align-items: center;
        padding: 6px 12px;

        &:has(>[data-press='true']) {
          @include pressed-d5-button();
        }

        & .dx-icon, i, svg {
          @include toolbar-icon();
          line-height: $toolbar-icon-size;
          margin-right: $toolbar-btn-padding;

          &.fa-link {
            font-size: 18px;
          }
        }
      }
    }

    .dx-menu-item-wrapper {
      border-bottom: 1px solid rgba(102, 102, 102, 0.08);
      $context-menu-icon-color: $gray-2;
      $context-menu-hover-bgcolor: $gray;

      .dx-menu-item {
        height: 36px;
        min-width: 230px;
        border-radius: 3px;

        .dx-icon {
          color: $context-menu-icon-color;
        }

       &.dx-menu-item-expanded, &.dx-state-hover {
         background: $context-menu-hover-bgcolor;
       }

        .dx-menu-item-popout::before {
          font-size: 20px;
          font-weight: 900;
          color: $context-menu-icon-color;
        }

        @media (max-width: 480px) {
          min-width: 130px;
          max-width: 200px;
          height: auto;

          .dx-menu-item-text {
            white-space: normal;
          }
        }

        span {
          @include toolbar-hidden-menu-text($config);
        }

        .dx-menu-item-text:has(.custom-dropdown-caret) {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        &.dx-state-hover {
          & > .dx-menu-item-content {
            .dx-icon {
              color: $gray-1;
            }

            .dx-menu-item-popout::before {
              color: $gray-1;
            }
          }
        }

        i {
          @include toolbar-icon();
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .new-group-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $gray;
      }

      .menu-item-container {
        display: flex;
        flex: 1 0;
        align-items: center;
        position: relative;

        span {
          padding-left: 25px;
          @include toolbar-hidden-menu-text($config);
        }

        i {
          position: absolute;
          @include toolbar-icon();
        }
      }

      .dx-menu-item.dx-state-disabled {
        opacity: 1 !important;
        color: $gray;

        span, .dx-icon {
          color: $gray;
        }
      }
    }
  }

  .help-context {
    .dx-menu-item-wrapper {
      &:last-child {
        .dx-menu-item-text {
          font-weight: 800;
        }
      }
    }
  }
}

@mixin field-focused() {
  border-color: $field-focused-color !important;
  .dx-label span {
    color: $field-focused-color;
  }
}

@mixin field-readonly() {
  background-color: $field-readonly-bg;

  .dx-texteditor-input, .static-input {
    color: $field-readonly-text-color !important;
  }
}

@mixin field-disabled() {
  @include field-readonly();
  opacity: 1;
  user-select: none;
}

@mixin field-border-without-radius {
  border-radius: 0 !important;

  &.dx-state-readonly {
    border-radius: 0 !important;
  }
}

@mixin field-border() {
  border: $field-border-width solid $field-border-color;
  border-radius: $field-border-radius !important;

  &.underline {
    border: none;
    border-bottom: $field-border-width solid $field-border-color;
    border-radius: 0 !important;
  }

  &.none {
    border: none;
  }

  &:not(.dx-state-readonly):hover {
    border-color: $primary !important;
  }
}

@mixin field-border-invalid() {
  box-shadow: inset 0 0 0 $field-border-width rgb(247 104 91 / 40%) !important;
  border: $field-border-width solid $field-error-color;
  .dx-label span {
    color: $field-error-color;
  }

  &.underline {
    border: none;
    border-radius: 0 !important;
    box-shadow: none !important;
    border-bottom: $field-border-width solid $error;
  }

  &.none {
    border: none;
    box-shadow: none !important;
  }
}

@mixin strong-field-border-invalid() {
  box-shadow: inset 0 0 0 1px rgb(247 104 91 / 40%) !important;
  border: 2px solid $field-error-color;
}

$group-font-size: 14px;
$group-font-color: #000;
$group-font-tab-color: $gray-2;
$group-font-color-active: $white;
$group-caption-padding: 8px;
$group-caption-border: 1px;

@mixin group-caption-wrapper-no-border {
  padding-bottom: $group-caption-padding;
  margin-bottom: $group-caption-padding;
  position: relative;

  &:after {
    content: '';
    height: $group-caption-border;
    width: 100%;
    background: $gray;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@mixin group-caption-wrapper-custom($position) {
  @include group-caption-wrapper-no-border;
  padding-bottom: 0;
  margin-bottom: 0;


  &:after {
    content: '';
    width: 3px;
    height: 100%;
    background: $gray;
    position: absolute;

    @if $position =='left' {
      display: none;
    }

    @else if $position =='right' {
      display: none;
    }

    @else if $position =='bottom' {
      width: 100%;
      height: $group-caption-border;
      top: 0;
      left: 0;
    }

    @else {
      width: 100%;
      height: $group-caption-border;
      bottom: 0;
      left: 0;
    }
  }

  .dx-tab {
    &.dx-tab-selected {
      &:after {
        z-index: 1;

        @if $position =='left' {
          height: 100%;
          width: 3px;
          right: -2px;
          top: 0;
        }

        @else if $position =='right' {
          height: 100%;
          width: 3px;
          left: 0;
          top: 0;
        }

        @else if $position =='bottom' {
          width: 100%;
          height: 3px;
          top: 0;
          left: 0;
        }

        @else {
          width: 100%;
          height: 2px;
          bottom: -1px;
          left: 0;
        }
      }

    }
  }

}

@mixin group-caption {
  font-size: $group-font-size;
  text-transform: none;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  color: $group-font-color;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin group-caption-active {
  @include group-caption;
  color: $group-font-color-active;
}
@mixin group-caption-inactive {
  @include group-caption;
  color: $group-font-tab-color;
}

@mixin group-caption-tab {
  padding-right: 7px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
  -webkit-user-drag: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin panel-border() {
  &.outlined {
    border: 2px solid $borderPanelColor;
    border-radius: 10px;
    margin: 10px 6px 6px !important;
    padding: 5px !important;
    box-shadow: 0 2px 5px 0 $borderPanelColor;
    height: calc(100% - 16px) !important; // верхній і нижній відступ

    &:has(> .accordion-title-wrapper:not(.hidden)),
    &:has(> .group-caption-wrapper:not(.hidden)) {
      padding-top: 7px !important;
    }
  }
}

@mixin panel-border-caption($caption-text-class) {
  position: absolute;
  top: 2px;
  left: 16px;
  width: calc(100% - 16px);

  #{$caption-text-class} {
    line-height: 16px;
    background: var(--group-caption-bg, #fff);
    z-index: 1;
    padding-left: 2px;
    padding-right: 2px;
  }
}

@mixin list-items-common-styles {
  .dx-item.dx-list-item {
    & .dx-list-item-content {
      & .dx-icon {
        color: #1A1A1A;
      }
    }

    & .dx-list-reorder-handle-container {
      & .dx-list-reorder-handle {
        color: #1A1A1A;
      }

      color: #1A1A1A;
    }

    &:hover:not(.dx-list-item-selected) {
      & .dx-list-item-content {
        & .dx-icon {
          color: $primary;
        }
      }

      & .dx-list-reorder-handle-container {
        & .dx-list-reorder-handle {
          color: $primary;
        }

        color: $primary;
      }
    }

    &-selected {
      & .dx-list-item-content .dx-icon {
        color: $dark;
      }

      & .dx-list-item-content,
      & .dx-list-reorder-handle-container {
        & .dx-list-reorder-handle {
          color: $dark;
          background-color: $gray;
        }

        color: $dark;
        background-color: $gray;
      }
    }
  }
}



@mixin vertical-tab-separates($separate-color: $gray-7-alpha) {
  .dx-tab:not(.dx-tab-selected) {
    &:after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      border-top: 1px solid $separate-color;
    }
  }

  .dx-tab.dx-tab-selected {
    &:before {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      border-top: 1px solid $separate-color;
    }
  }
}

@mixin disabled-nav-tab-button {
  .dx-button.dx-state-disabled.dx-tabs-nav-button {
    .dx-button-content {
      opacity: 1 !important;

      .dx-icon {
        color: $gray-7;
      }
    }
  }
}

@mixin form-button {
  height: 40px;
  min-width: 120px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
}

@mixin booleanEditorStyleSelector($color) {
  height: $field-control-height;

  .dx-buttongroup {
    height: 100% !important;

    &.dx-buttongroup-mode-contained {
      box-shadow: none;

      .dx-button {
        height: auto !important;
        .dx-button-text {
          font-size: 13px !important;
          line-height: 16px;
          font-weight: 400;
          color: $gray-2;
        }
      }
    }

    .dx-buttongroup-item.dx-button-mode-contained,
    .dx-buttongroup-item.dx-button-mode-outlined {
      border-top: 1px solid $color;
      border-bottom: 1px solid $color;
      border-left: 1px solid $color;
      border-right-width: 0;
    }

    .dx-button-mode-contained.dx-buttongroup-first-item.dx-button,
    .dx-button-mode-outlined.dx-buttongroup-first-item.dx-button {
      border-start-start-radius: 4px;
      border-end-start-radius: 4px;
    }

    .dx-button-mode-contained.dx-buttongroup-last-item.dx-button,
    .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
      border-right: 1px solid $color;
    }
  }
}

@mixin inline-editing-row-borders-styles {
  .dx-data-row td.dx-pointer-events-none {
    border-right-width: 1px;
    border-right-color: $gray-3;
  }
  .dx-row.dx-edit-row  {
    border-top: 1px solid $gray-3;
    border-bottom: 1px solid $gray-3;
  }
}

@function get_title_config($size) {
  $config: (
    'form-title': 16px,
    'form-title-padding': 3px 15px 3.5px 15px,
    'group-font-size': 14px,
    'table-toolbar-height': 24px,
  );

  @return $config;

}

@function get_icon_config() {
  $config: (
    'tab-icon-size': 18px,
  );
@return $config;
}
