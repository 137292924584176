$sizes:(
  '25': 25%,
  '50': 50%,
  '75': 75%,
  '100': 100%,
  'auto': auto
);

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }

.min-vw-100 { min-width: 100vw !important; }
.min-vh-100 { min-height: 100vh !important; }

.vw-100 { width: 100vw !important; }
.vh-100 { height: 100vh !important; }

.overflow-hidden { overflow: hidden !important; }
.overflow-auto { overflow: auto !important; }

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}