@use "scss/ui3/variables" as *;

@mixin between-filter () {
  display: flex;
  width: 100%;
  min-width: $field-control-min-width;

  &.title-with-label {
    // Для філдів з заголовком на бордері, знімаємо обмеження по висоті 32px
    height: auto;

    .number-between-control-to.d5-field {
      height: auto;
    }
  }

  .number-between-dash {
    display: flex;
    align-items: center;
    color: $gray-3;
    font-size: 24px;
  }

  .d5-field {
    border: none!important;

    &.number-between-control-from {
      // 7px це dash
      min-width: calc(50% - 7px);
      height: calc(100% - 0.05px);
    }

    &.number-between-control-to {
      // 26px ширина контейнера clear кнопки
      min-width: calc(50% - 26px);
      height: calc(100% - 0.05px);
    }
  }

  .dx-show-clear-button .dx-texteditor-buttons-container {
    height: 100%;
  }
}

.d5-layout-group {
  display: flex;
  flex: 1 1 auto;

  .filter-field-container {
    display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    .field-container-between {
      @include between-filter();
    }

    .d5-field-container {
      flex-grow: 1;
    }

    .dx-field-item-label-location-top {
      &+.dx-field-item-content {
        .filter-field-container {
          padding-left: 0 !important;
        }
      }
    }

    & .dx-button-content {
      & .dx-icon.far.fa-list {
        vertical-align: baseline;
      }
    }
  }


  // здесь важен порядок стилей, при изменении расположения фильтров (row / col) возможно нужны будут коррективы в данных стилях
  &.flex-column {
    .without-widget {
      .operation-type-widget {
        display: block;
        opacity: 0;
      }
    }
  }

  &.flex-row {
    .without-widget {
      .operation-type-widget {
        display: none;
      }
    }
  }
}
