@use "sass:color";
@use "./variables" as *;

$button-disabled-background: rgba(0, 0, 0, 0.1) !default;
$button-shadow-color: rgba(0, 0, 0, 0.24) !default;
$button-focused-shadow-color: color.adjust($button-shadow-color, $alpha: 0.08) !default;
$button-active-shadow-color: color.adjust($button-shadow-color, $alpha: 0.08) !default;
$button-hover-shadow-color: $button-shadow-color !default;
$button-disabled-icon-opacity: 0.6 !default;
$button-disabled-text-color: color.adjust($button-disabled-background, $alpha: 0.25) !default;
$button-border-radius: 2px !default;


$material-button-box-shadow-size: 0 1px 3px !default;
$material-button-active-box-shadow-size: 0 4px 6px !default;
$material-button-horizontal-padding: 16px !default;
$material-button-vertical-padding: 5px !default;
$material-base-icon-size: 18px !default;
$material-text-button-horizontal-padding: 5px;
$material-button-height: 28px !default;
$material-button-min-width: 48px !default;
$material-button-padding: $material-button-vertical-padding $material-button-horizontal-padding;
$material-button-icononly-padding: $material-button-vertical-padding;
$material-button-icon-size: $material-base-icon-size;
$material-button-icon-text-padding: 12px;
$material-normal-button-shadow: $material-button-box-shadow-size $button-shadow-color;
$material-normal-button-active-state-shadow: $material-button-active-box-shadow-size $button-active-shadow-color;
$material-normal-button-hovered-state-shadow: $material-button-box-shadow-size $button-hover-shadow-color;
$material-normal-button-focused-state-shadow: $material-button-box-shadow-size $button-focused-shadow-color;


$button-danger-bg: $error;
$button-success-color: $base-success;
$button-default-color: $primary;
$button-warning-color: $warn;
$button-main-scheme-color: $gray-2;

@mixin dx-button-styling-variant(
  $background-color, $text-color, $shadow-color,
  $hover-background-color, $hover-shadow-color, $focused-background-color,
  $focused-shadow-color, $active-background-color, $active-shadow-color,
  $disabled-background-color, $icon-color
) {
  background-color: $background-color;
  color: $text-color;
  box-shadow: $shadow-color;

  .dx-icon {
    color: $icon-color;
  }

  &.dx-state-hover {
    background-color: $hover-background-color;
    box-shadow: $hover-shadow-color;
  }

  &.dx-state-focused {
    background-color: $focused-background-color;
    box-shadow: $focused-shadow-color;
  }

  &.dx-state-active {
    background-color: $active-background-color;
    box-shadow: $active-shadow-color;
  }

  &.dx-state-disabled {
    background: $disabled-background-color;
    box-shadow: none;

    .dx-button-text,  .dx-icon {
      color: $button-disabled-text-color;
    }
  }

  .dx-inkripple-wave {
    background-color: color.change($text-color, $alpha: 0.2);
  }
}

@mixin dx-button-styling(
  $button-bg, $button-color, $button-hover-bg,
  $button-focused-bg, $button-active-bg, $button-icon-color
) {
  border-radius: $button-border-radius;

  @include dx-button-styling-variant(
                  $button-bg,
                  $button-color,
                  $material-normal-button-shadow,
                  $button-hover-bg,
                  $material-normal-button-hovered-state-shadow,
                  $button-focused-bg,
                  $material-normal-button-focused-state-shadow,
                  $button-active-bg,
                  $material-normal-button-active-state-shadow,
                  $button-disabled-background,
                  $button-icon-color
  );
}

@mixin dx-button-flat-color-styling($button-color, $hover-bg, $focused-bg, $active-bg) {
  @include dx-button-styling-variant(
                  transparent,
                  $button-color,
                  none,
                  $hover-bg,
                  none,
                  $focused-bg,
                  none,
                  $active-bg,
                  none,
                  transparent,
                  $button-color
  );
}

@mixin dx-button-outlined-color-styling($button-color, $border-color, $hover-bg, $focused-bg, $active-bg) {
  @include dx-button-styling-variant(
                  transparent,
                  $button-color,
                  none,
                  $hover-bg,
                  none,
                  $focused-bg,
                  none,
                  $active-bg,
                  none,
                  transparent,
                  $button-color
  );

  border: 1px solid $border-color;
}


@mixin flat-button($bg) {
  $text-hover-bg: color.change($bg, $alpha: 0.08);
  $text-focused-bg: color.change($bg, $alpha: 0.08);
  $text-active-bg: color.change($bg, $alpha: 0.36);

  @include dx-button-flat-color-styling($bg, $text-hover-bg, $text-focused-bg, $text-active-bg);
}

@mixin flat-dropdown-button($bg) {
  @include flat-button($bg);
  border-color: $bg;
  &.dx-state-hover {
    $text-focused-bg: color.change($bg, $alpha: 0.08);
  }
  i, span, svg {
    color: inherit;
  }
}

@mixin contained-drop-down($button-bg, $button-color: #fff) {
  $button-active-bg: color.adjust($button-bg, $lightness: -19.5%);
  $button-hover-bg: color.adjust($button-bg, $lightness: -8.5%);
  $button-focused-bg: color.adjust($button-bg, $lightness: -8.5%);
  $button-icon-color: $button-color;

  @include dx-button-styling(
                  $button-bg,
                  $button-color,
                  $button-hover-bg,
                  $button-focused-bg,
                  $button-active-bg,
                  $button-icon-color
  );
}
