@use "scss/ui3/variables" as *;
@use "src/scss/ui3/mixins" as *;
@use "src/scss/ui3/tag-mixins" as *;

.dx-data-row {
  .multiselect {
    &-column {
      margin: -7px 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-content: flex-start;
    }

    &-container {
      padding: 4px 7px;
      height: 23.5px;

      display: flex;
      align-items: center;
      border-radius: 2px;
      line-height: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid transparent;
      margin: 3px 2px;
      @include tagboxStylingModes(false);
    }

    &-cell {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-w100 {
      width: 100%;
    }
  }
}

.dx-data-row {
  &.dx-selection {
    td:not(.dx-focused):not(.d5-selected-cell) {
      .multiselect-container.styling-mode-text {
        color: $dark !important;
      }
    }
  }
}


