$grid-gap: 5px;
$grid-cols: 12;
$grid-rows: 12;
$full-screen-padding: 3px 10px 5px;
$toolbar-padding: 10px 32px 0 16px;

$toolbar-min-height-desktop: 24px;
$toolbar-min-height-mobile: 32px;

$toolbar-margin-bottom: 8px;

$generic-compact-toolbar-height: 26px;

$generic-toolbar-height: 36px;

$material-toolbar-height: 76px;

$material-compact-toolbar-height: 56px;

$scrollbar-track-bg: rgba(191, 191, 191, 0.7)
