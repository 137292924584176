@use "scss/ui3/variables" as *;
@use "configs/base"as *;
@use "configs/field"as *;
@use "./mixins"as *;
@use "scss/modes" as *;
@use 'sass:map';
@use 'sass:list' as list;

$popup-top-toolbar-height: 42px;
$popup-title-color: $primary;
$popup-bluebar-height: 10px;
$popup-bluebar-bg: $primary;

.react-draggable {
  border-radius: 5px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  display: flex;
  z-index: 1000;
  cursor: default !important;
  transition: opacity 0.2s ease-in;
}

.mobile-fullscreen-qr-scanner {
  .react-draggable {
    transform: unset !important;
  }
}

// for desktop
$popup-title-padding: $popup-design-padding $popup-design-padding 0 $popup-design-padding;
$popup-buttons-container-paddings: 16px $popup-design-padding;
$popup-content-side-padding: $popup-design-padding - $layout-item-side-margin;
$popup-content-padding: 0 $popup-content-side-padding;
// for mobile
$mobile-popup-content-padding: 0 10px 10px;
$mobile-popup-title-padding: 20px 20px 0 10px;

.popup-content-margin {
  margin: 0 $popup-design-padding;
}

.popup-content-margin-with-fields {
  margin: $popup-content-padding;
}

.popup-content-without-buttons {
  padding-bottom: 16px;
}

.popup-content-margin-with-fields-scroll {
  margin-left: $popup-content-side-padding;
  //в скролі є відступи праворуч для скролеру, тому праворуч відступ більший ніж ліворуч
  //d5-scrollview 3px + simplebar-content 3px
  margin-right: $popup-content-side-padding - 6;
}

.popup {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;

  @media (orientation: portrait) {
    height: 100%;
  }

  .popup-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    @media (orientation: portrait) {
      height: 100%;
    }


    & .simplebar-content-wrapper {
      outline: none;
    }
  }

  .popup-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & .subblock-settings-title {
      font-size: $medium-font-size;
      margin: 5px 0 10px;
      padding: 0 8px;
      color: $primary;
      overflow: hidden;

      &:after {
        content: "";
        display: block;
        background-color: $primary;
        height: 1px;
        position: relative;
        right: 2px;
        width: 101%;
        top: 0;
      }
    }
  }

  .popup-buttons-container {
    padding: $popup-buttons-container-paddings;

    &.border-top-box-shadow {
      box-shadow: 0 0 16px 0 #00000040;
    }
  }

  .popup-title-container {

    padding: $popup-title-padding;
    position: relative;

    .popup__title-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: $popup-top-toolbar-height;
      margin-top: 0;

      &.no-title-text {
        justify-content: flex-end !important;
        height: $popup-top-toolbar-height - 20px;
      }

      .caption-container {
        display: flex;
        padding-right: 10px;
        padding-bottom: 10px;
        height: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        color: $popup-title-color;
        overflow: hidden;

        .popup-title {
          flex-grow: 1;
          padding-right: 5px;
        }

        .popup__title__text-modifier {
          flex-shrink: 0;
          overflow: hidden;
        }
      }

      .caption-wrapper {
        overflow: hidden;
      }

      .popup__title-suffix-icons {
        display: flex;

        @include icon($gray-2, $toolbar-FA-icon-size);

        .quick-tips-icon svg {
          width: auto;
        }

        .close::before {
          font-size: 20px;
          font-weight: 500;
        }

        .dx-button {
          margin-right: 8px;
          height: 24px;
          min-width: 24px;

          &:has(.close) {
            margin-right: 0;
          }

          .dx-button-content {
            padding: 0;
            display: flex;
          }
        }

        i {
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }

        & > i {
          margin-top: 4.5px;
        }
      }
    }
  }

  .cursor-move {
    cursor: move;
  }

  .cursor-default {
    cursor: default;
  }

  .popup-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .bluebar {
    position: absolute;
    left: 0;
    top: 0;
    height: $popup-bluebar-height;
    width: 100%;
    background-color: $popup-bluebar-bg;
  }

  .dx-popup-title {
    padding: 0;
    border: 0;
  }

  .dx-overlay-content {
    &.dx-popup-draggable {
      .dx-popup-content {
        padding: $popup-content-padding;
      }
    }
  }

  &.mobile-popup {
    .popup-title-container {
      padding: $mobile-popup-title-padding;
    }

    .popup-wrapper {
      padding: $mobile-popup-content-padding;
    }

    .dx-overlay-content {
      &.dx-popup-draggable {
        .dx-popup-content {
          padding: $mobile-popup-content-padding;
        }
      }
    }
  }
}

.dx-scrollview-content {
  .dx-list-select-all {
    display: flex;
    align-items: center;
  }

  .dx-list-select-all-label {
    padding-left: 10px;
  }

  .dx-list-item-before-bag {
    width: 27px;
  }
}

.hidden-select-all {
  .dx-list-select-all {
    display: none;
  }
}

.dx-dropdownbutton-content {
  max-height: 100vh;
}

.dx-datebox-wrapper-calendar {
  .dx-toolbar-button {

    .dx-popup-cancel,
    .dx-button-today {
      .dx-button-text {
        color: $gray-2;
      }
    }
  }
}

$config: base_config();
$link-config: field_config();

.d5-link {
  font-size: map.get($config, 'font-size') !important;
  min-height: map.get($link-config, "height") !important;
  padding: list.nth(map.get($link-config, "input-padding"), 1) 0;
}

.jsx-decoration-link {
  display: inline-block;
}


.file-viewer-preview-wrapper {
  height: 100%;
  padding-bottom: 24px;
}
