@use 'variables' as *;
@use "mixins" as *;
@use 'scss/ui3/tables/variables' as table_vars;
@use "../prepend-slash" as *;
@use 'sass:map';

@mixin horizontal-borders() {
  .dx-tab::before,
  .dx-tab::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .dx-tab:not(.dx-tab-selected)::before, .dx-tab:not(.dx-tab-selected)::after {
    height: 12px;
    width: 0;
    border-left: 1px solid $gray;
  }

  .dx-tab:not(.dx-tab-selected)::before {
    left: -1px;
  }

  .dx-tab:not(.dx-tab-selected)::after {
    right: -1px;
  }

  .dx-tab.dx-state-hover::before, .dx-tab.dx-state-hover::after  {
    content: none;
  }

  .dx-tab.dx-tab-selected::before {
    left: -1px;
  }

  .dx-tab.dx-tab-selected::after {
    left: inherit;
    right: -1px;
  }

  .dx-tab.dx-tab-selected::before, .dx-tab.dx-tab-selected::after {
    height: 100%;
    width: 1px;
    background: $white;
  }
}

//стили таб панели
.tab-form {
  display: flex;
  flex: 1 1 auto;

  & > .dx-tabpanel-tabs .tabs-position-top,
  & > .dx-tabpanel-tabs .tabs-position-bottom {
    height: 36px;
  }

  & > .dx-tabpanel-tabs .tabs-position-left,
  & > .dx-tabpanel-tabs .tabs-position-right {
    height: 100%;
  }

  &.tab-column-full-width {
    .dx-tabs-wrapper {
      display: flex;
      overflow: hidden;
    }

    > .dx-tabpanel-tabs .dx-tab {
      flex: 1;
    }

    .dx-tabs-nav-button.dx-button.dx-tabs-nav-button.dx-button-has-icon:not(.dx-button-has-text).dx-state-disabled {
      z-index: 1;
    }

    .dx-tabs-scrollable {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .tabs-wrapper {
    display: flex;

  }

  &.with-tab-settings-button {
    & > .dx-tabpanel-tabs {
      display: flex;
      @include disabled-nav-tab-button;

      & > .dx-tabs {
        width: calc(100% - 32px);
      }
    }

    & > .dx-tabpanel-tabs.tabs-position-left,
    & > .dx-tabpanel-tabs.tabs-position-right, {
      & > .dx-tabs {
        width: 100%;
        height: calc(100% - 32px);
      }
    }
  }

  //заголовки

  & > .dx-tabpanel-tabs {
    // панель под вкладками
    & > .dx-tabs {
      background: transparent;
    }

    .dx-tab {
      // высота 30
      padding: 8px 17px;
      // Робимо такий базис, бо в DX дефолтно 100% і тому таби розтягуються на усю ширину
      flex-basis: auto;
      height: 32px;
      max-height: 32px;
      flex-grow: 0;

      &:not(.dx-tab-selected):hover {
        background-color: $gray-6;
      }

      .dx-tab-content {
        .tab-content-wrapper {
          display: flex;
          align-items: center;
        }
      }

      .dx-tab-text, .tab-caption {
        @include group-caption-inactive;
        @include group-caption-tab;
        padding-left: 7px;
        line-height: 24px;
      }

      //активный таб
      &.dx-tab-selected {
        background: $gray-2;
        color: $gray-2;
        z-index: 1;

        &:after {
          z-index: 1;
          height: 3px;
          background-color: $gray-2;
        }

        .dx-tab-text, .tab-caption {
          @include group-caption-active;
          @include group-caption-tab;
        }

        .dx-icon {
          color: $white;
        }
      }
    }
  }

  & > .dx-tabpanel-tabs.tabs-position-left {
    @include group-caption-wrapper-custom(left);
    @include vertical-tab-separates($gray-7-alpha);
    flex-direction: column;
    padding-right: 10px;

    .dx-tab-content {
      .tab-content-wrapper {
        max-width: 166px;
        flex-direction: column;

        .tab-caption {
          padding-top: 3px;
        }
      }
    }
  }


  & > .dx-tabpanel-tabs.tabs-position-right {
    @include group-caption-wrapper-custom(right);
    @include vertical-tab-separates($gray-7-alpha);
    flex-direction: column;
    padding-left: 10px;

    .dx-tab-content {
      .tab-content-wrapper {
        max-width: 166px;
        flex-direction: column;

        .caption-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;

          .tab-caption {
            padding-top: 5px;
          }
        }
      }
    }
  }

  & > .dx-tabpanel-tabs.tabs-position-left.with-icons-and-title-tabs,
  & > .dx-tabpanel-tabs.tabs-position-right.with-icons-and-title-tabs {
    .dx-tab {
      height: 54px;
      max-height: 54px;
    }
  }

  &:not(.custom-settings-tabs) > .dx-tabpanel-tabs.tabs-position-bottom {
    @include group-caption-wrapper-custom(bottom);
  }

  &:not(.custom-settings-tabs) > .dx-tabpanel-tabs.tabs-position-top {
    @include group-caption-wrapper-custom(top);
  }

  &:not(.custom-settings-tabs) > .dx-tabpanel-tabs.tabs-position-bottom,
  &:not(.custom-settings-tabs) > .dx-tabpanel-tabs.tabs-position-top {
    @include horizontal-borders();
  }

  &.dx-tabpanel-tabs-position-top > .dx-tabpanel-container {
    //высота тулбара в среднем размере интерфейса
    $medium-toolbar-height: 32px;
    //переопределяем здесь отступы ДХ потому что они считают их асинхронно и в какой-то момент не правильно высчитываются (типа пустой тулбар)
    margin-top: -$medium-toolbar-height !important;
    padding-top: $medium-toolbar-height !important;
    height: 100%;
    overflow: hidden;
  }

  .group-container {
    height: inherit;
  }

  .dx-tabs-nav-button.dx-button.dx-tabs-nav-button.dx-button-has-icon:not(.dx-button-has-text).dx-state-hover {
    background-color: transparent;
  }

  .dx-tabs-nav-button:hover {
    background-color: $gray-6 !important;
  }

}
.custom-settings-tabs {
  & > .dx-tabpanel-tabs {
    .dx-tab {
      border: 1px solid $gray;

      .dx-tab-text, .tab-caption {
        color: $gray-2;
      }
    }
  }
}

@each $size in table_vars.$row-sizes {
  #root.root-#{$size} {
    $config: get_icon_config();

    .tab-form {
      //заголовки
      & > .dx-tabpanel-tabs {
        .dx-tab-content {
          @include icon(null, map.get($config, 'tab-icon-size'));
        }
      }
    }
  }
}
