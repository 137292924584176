@use 'scss/ui3/tables/variables' as table_vars;
@use 'scss/ui3/configs/dx-list' as *;
@use 'src/scss/ui3/mixins' as *;
@use "scss/ui3/variables" as *;
@use 'sass:map';


.custom-list {
  .search-field {
    padding: 0;
  }

  &.row-lines {
    .dx-list-item {
      border-top: 1px solid $gray;
    }

    .dx-list-item:last-of-type {
      border-bottom: 1px solid $gray;
    }
  }
}

@each $size in table_vars.$row-sizes {
  .root-#{$size}, .root-#{$size} ~ .dx-overlay-wrapper, .root-#{$size} .dx-overlay-wrapper {
    $config: get_list_config($size);

    .custom-list {
      .dx-list-item {
        & > .dx-list-item-content {
          padding: map.get($config, 'padding-dx-item');
          font-size: map.get($config, 'font-size');
          line-height: map.get($config, 'line-height');

          .dx-button.dx-button-has-icon {
            @include icon_button($size)
          }
        }

        .dx-list-item-after-bag.dx-list-reorder-handle-container {
          $handler_cfg: map.get($config, 'reorder-handler');
          width: map.get($handler_cfg, 'conainer-width');

          .dx-list-reorder-handle {
            font-size: map.get($handler_cfg, 'font-size');
            line-height: map.get($handler_cfg, 'font-size');
            padding: map.get($handler_cfg, 'handler-padding');
            width: map.get($handler_cfg, 'conainer-width');
            height: map.get($handler_cfg, 'conainer-width');
          }
        }
      }
    }

    .header-filter-list {
      .dx-list-item {
        & > .dx-list-item-content {
          padding-top: 7px;
          padding-left: 7px;
          display: flex;
          align-items: center;
        }

        .dx-list-item-before-bag .dx-list-select-checkbox {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

.custom-list.row-lines {
  @include list-items-common-styles()
}

