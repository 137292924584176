.custom-bold-font {
  font-weight: bold !important;
}

.custom-italic-font {
  font-style: italic !important;
}

.custom-underline-font {
  text-decoration: underline !important;
  text-underline-offset: 2px !important;
}