@use "../modes" as *;
@use "./configs/field" as *;
@use "./configs/icon-button" as *;
@use "./mixins" as *;
@use "scss/ui3/variables" as *;

$margin-after-search-field: 5px;
.buttons-group {
  height: 100%;
}

.dual-list-selector-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  @include field-border();

  &.state-invalid {
    @include field-border-invalid();
  }

  &.list-is-readonly {
    @include field-readonly();
    .dx-widget {
      opacity: 1;
    }
    .dx-list-item {
      color: $field-readonly-text-color !important;
      background-color: transparent !important;
      pointer-events: none;
    }

    .dx-list-search {
      pointer-events: none;
    }
  }

  .dual-list-selector-clear-button {
    position: absolute;
    right: 30px;
    top: 18px;
  }

  .source-list, .destination-list {
    flex: 1 1 0;

    .dx-scrollable-wrapper {
      padding-right: 5px;
    }

    .list-item-container {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: $medium-icon-button-size;

      .list-item-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1 auto;
      }

      .list-item-buttons-container {
        position: absolute;
        right: 0;
      }
    }
  }

  .destination-list {
    padding-top: $field-control-height + $margin-after-search-field;
  }

  .list-arrow-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.hide-effects.dual-list-selector-wrapper {
  pointer-events: none;
  @include field-disabled();

  .dx-widget {
    opacity: 1;
  }
}
