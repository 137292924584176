@use "buttons-color-scheme" as *;
@use "variables" as *;

$toolbar-icon-color: $gray-2;
$toolbar-icon-hover-color: $gray-1;
$toolbar-btn-hover-bg: $gray;

@mixin toolbar-button($icon-size: $toolbar-icon-size) {
  .toolbar-item.enabled {
    .toolbar-button.styling-mode-text, .hidden-menu-button,
    .toolbar-button.styling-mode-outlined, .toolbar-button.styling-mode-contained {
      &.color-scheme-normal {
        color: $toolbar-icon-color;

        &.dx-state-hover {
          background: $toolbar-btn-hover-bg;

          i, span, svg, .dx-icon {
            color: $toolbar-icon-hover-color !important;
          }
        }
      }

      i, span, svg, .dx-icon {
        color: inherit !important;
      }
    }

    .toolbar-group-button {
      .dx-buttongroup-wrapper {
        column-gap: 0 !important;
      }

      &.styling-mode-text, &.styling-mode-outlined {
        &.color-scheme-normal {
          .dx-button {
            &.dx-state-hover {
              background: $toolbar-btn-hover-bg;

              .dx-dropdownbutton-toggle {
                .dx-icon-spindown {
                  background: $toolbar-btn-hover-bg;
                }
              }

              .dx-icon-spindown, i, span, svg {
                color: $toolbar-icon-hover-color;
              }
            }
          }

          i, span, svg {
            color: $toolbar-icon-color;
          }
        }
      }
    }
  }
  .toolbar-item.disabled {
    &, .toolbar-group-button, .toolbar-button {
      opacity: 1 !important;
    }

    .styling-mode-text {
      &.color-scheme-normal {
        i, span {
          color: $gray;
        }

        svg {
          path {
            fill: $gray;
          }

          rect {
            stroke: $gray;
          }
        }
      }
    }
  }

  .toolbar-group-button, .toolbar-button, .dx-dropdownmenu-button {
    i:not(.custom-icon).dx-icon:not(.dx-icon-spindown) {
      @include toolbar-icon();
    }

    i.custom-icon {
      @include custom-toolbar-icon();
    }

    img.dx-icon, svg.dx-icon, .dx-icon > svg {
      height: $icon-size;
      width: $icon-size;
    }

    .dx-button-text {
      text-transform: none;
    }
  }
}

@mixin custom-toolbar-icon() {
  font-size: $toolbar-FA-icon-size !important;
  height: $toolbar-icon-size;
  width: $toolbar-icon-size;
}


@mixin toolbar-icon() {
  font-size: $toolbar-FA-icon-size;
  height: $toolbar-icon-size;
  width: $toolbar-icon-size;
}

@mixin pressed-d5-button() {
  background-color: $primary-15 !important;
  border-radius: 2px;
  width: 100%;
}
@mixin pressed-d5-firefox-button() {
  position: absolute;
  left: 0;
  top: 0;
  padding: 11px 8px;
  @include pressed-d5-button();
}
