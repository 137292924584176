@use "header-cells" as *;
@use "data-cells" as *;
@use "../../../scss/ui3/configs/table" as *;
@use 'scss/ui3/tables/variables' as table_vars;
@use "scss/ui3/variables" as *;
@use 'scss/ui3/tables/mixins' as *;
@use "inline-editing" as *;

.table-wrapper, .dx-sortable-clone.dx-sortable-dragging {

  @each $size in table_vars.$row-sizes {
    &.row-size-#{$size} {
      @include inline-editors-size(row_config($size));
      @include header-cell-size(header_config($size));
      @include data-row-size(row_config($size));
      @include tree-list-arrow-columns;
    }

    &.row-size-small {
      .dx-switch {
        height: 10px;
      }
    }
  }

  .dx-state-disabled {
    box-shadow: none !important;

    input {
      box-shadow: none !important;
    }

    .dx-placeholder,
    .dx-texteditor-buttons-container {
      display: none;
    }
  }

  // класс-модификатор чтобы убрать все бордеры
  &--hidden-borders {
    .dx-treelist-rowsview,
    .dx-datagrid-rowsview {
      border-top: none;
    }
  }

  .dx-treelist-focus-overlay {
    &:after {
      background-color: unset;
    }
  }

  .dx-placeholder {
    padding-left: 6px;
  }

  //Стилі для колонки-клону при drag&drop
  .dx-treelist .dx-command-drag {
    padding-bottom: 7px !important;
    padding-top: 7px !important;
    padding-left: 3px !important;
  }
  .dx-treelist .dx-command-drag .dx-treelist-drag-icon:before{
    content: '\2807';
  }
}

.custom-cm-container {
  height: 30px;
  display: flex;
  align-items: center;
  width: 100%;

  &.section-item {
    padding-left: 15px;
  }

  .dx-icon {
    flex: 0;
  }

  .custom-cm-text {
    vertical-align: baseline !important;
    margin-left: 8px;
    flex: 1 1;
    max-width: 200px;
    overflow: hidden;
  }

  .custom-cm-hotkey {
    font-weight: bold !important;
    flex: 0;
  }
}
