// class uses in kanban and tileView
.card {
  .field-label {
    cursor: pointer;
    color: black;
    font-weight: bold;
    padding: 0;
  }

  .flex-row {
    width: 100%;
  }
  &:hover {
    box-shadow: rgba(20, 83, 248, 0.2) 0 3px 3px 0;
  }

  .readonly-state {
    .caption-star-container {
      display: none;
    }
  }

  .d5-field.dx-texteditor {
    background-color: white;
  }

  .d5-field.dx-state-readonly {
    border-color: transparent !important;
    pointer-events: none;
    border: none;
    background-color: inherit;

    //нужно для того чтобы перекрывало компонент, так как за контрол таскать нельзя
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    &:not(.text-area-control) {
      .dx-texteditor-input-container {
        display: flex;
        align-items: center;

        input {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .form-field[class*="col"]:not(.col-auto) {
    .d5-field {
      min-width: unset;
    }

    flex-basis: auto;
  }
}
