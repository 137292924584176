@use 'mixins' as *;
@use 'tables/utils' as *;
@use "tables/mixins" as table_mixins;
@use 'scss/ui3/tables/variables' as table_vars;
@use "scss/ui3/variables" as *;
@use 'configs/field' as *;
@use "configs/base" as *;
@use 'sass:map';
@use 'sass:math';

@mixin field-size($size, $config) {
  $font-size: map.get($config, 'font-size');
  $font-weight: map.get($config, 'font-weight');
  $line-height: map.get($config, 'line-height');
  $input-padding: map.get($config, 'input-padding');
  $textarea-padding: map.get($config, 'textarea-padding');
  $min-height: map.get($config, 'height');
  $min-inline-height: map.get($config, 'inline-height');
  $icon-config: map.get($config, 'icons');
  $button-config: map.get($config, 'button');
  $exclamation-config: map.get($config, 'error-exclamation-sign');

  font-size: $font-size;
  line-height: $line-height;
  height: $min-height;

  input, textarea, .static-input {
    &.with-value {
      padding-top: 8px !important;
    }

    padding: $input-padding !important;
    font-size: $font-size;
    line-height: inherit;
    font-weight: $font-weight;
  }

  textarea {
    padding: $textarea-padding !important;
  }

  &.dx-textarea, textarea {
    line-height: 20px !important;
  }

  .dx-colorbox-input {
    padding-left: 33px !important;
  }

  .dx-dropdowneditor-button {
    min-width: map.get($button-config, 'width');
    height: map.get($button-config, 'height');
    margin-inline-end: 8px !important;


    & .dx-button-content {
      & .dx-dropdowneditor-icon {
        &:before {
          color: $gray-1;
          margin-top: -7px;
        }
      }

      &:hover {
        border-radius: $field-border-radius;
        background-color: $text-button-background;
      }
    }
  }

  .dx-texteditor-container {
    .dx-texteditor-buttons-container {
      height: 30px;
      align-self: center;
    }
  }

  .dx-button:not(.operation-type-widget) {
    // всім дефолтним кнопкам у яких вказано тільки текст бг сірий
    &.dx-button-mode-text.dx-state-focused, &.dx-button-mode-text.dx-state-hover {
      background-color: $text-button-background;
    }

    &:not(.dx-buttongroup-item) {
      @include table_mixins.select-dropdown-button-base-styles($button-config);
      max-width: 100px;
      margin-left: 0;
      -webkit-margin-end: 8px;
      margin-inline-end: 8px;

      &.location {
        &__after {
          margin-right: 8px;
        }

        &__before {
          margin-left: 8px;
        }
      }
    }

    box-shadow: none;

    .dx-button-content {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .dx-icon {
      width: map.get($icon-config, 'width');
      height: map.get($icon-config, 'height');
      font-size: map.get($icon-config, 'font-size');
      line-height: map.get($icon-config, 'line-height');
    }
  }

  &.multi-select-control, &.custom-tagbox {
    // Стилі потрібні для коректного розрахунку висоти по контенту випадаючих списків з тегбоксами
    height: auto !important;
    min-height: $field-inline-height;


    &.dx-state-readonly, &.dx-state-disabled {
      input {
        display: none;
      }
    }
  }

  .dx-placeholder {
    height: 100%;
    //Поруч ще є плейсхолдер. Коли фокус в полі то все що під полем зсівається на мікропіксель і контент розмивається
    //тому ставипо поле на рівень 1, а плейсхолдер на -1 рівень
    z-index: -1;

    &:before {
      padding: $input-padding !important;
      font-size: $font-size;
      line-height: inherit;
      height: auto !important;
    }
  }

  .dx-texteditor-input-container::after {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: map.get($exclamation-config, 'width') !important;
    height: map.get($exclamation-config, 'height') !important;
    font-size: map.get($exclamation-config, 'font-size') !important;
  }

  .dx-tag-container, .dx-texteditor-input-container {
    padding-top: 0;
    padding-bottom: 3px;
    min-height: $min-height - 4; //- бордеры по 2пх

    &:not(:has(.dx-tag)) {
      padding: 0 4px 0 0 !important;
    }

    .dx-tag {
      margin: map.get($config, 'tag-margin');
      //это если вложенные таг в таг
      .dx-tag {
        margin: 0;
      }

      .dx-tag-content {
        font-size: map.get($config, 'tag-font-size');
        line-height: map.get($config, 'tag-lineheight');
        padding: map.get($config, 'tag-content-padding');
      }

      &.dx-tagbox-multi-tag {
        .dx-tag-content {
          padding: map.get($config, 'tag-content-padding-without-clear-button');
        }

      }
    }
  }

  .custom-tagbox__content {
    padding-bottom: 0;
  }
}

@mixin item-margin($size) {
  padding: 7px $layout-item-side-margin $layout-item-side-margin;
}


@mixin item-margin-class($size) {
  .item-margin {
    @include item-margin($size)
  }
}


@mixin get-field-size($size) {
  $config: field_config();
  $fontSize: map.get($config, 'font-size');
  $height: map.get($config, 'height');
  .dx-button-text {
    font-size: $fontSize;
  }

  .field-label {
    font-size: $field-label-font-size;
  }

  .decor-text {
    font-size: $fontSize;
  }

  .d5-field {
    @include field-size($size, $config);
  }

  .d5-field.radio-group-field {
    height: auto;
  }

  .d5-field.dx-state-readonly {
    .dx-tag,
    .dx-tagbox-multi-tag {
      .dx-tag-content {
        padding: 1px 5px 1px 5px;

        .dx-tag-remove-button {
          display: none;
        }
      }
    }
  }
  .parent-field-container {
    min-width: 120px;
    @include field-size($size, $config);

    // Для філдів з заголовком на бордері, знято обмеження по висоті 32px
    &.parent-field-with-location-label-floating, &.parent-field-with-location-label-on_border {
      height: auto;
    }

    &:has(.multi-select-control) {
      height: auto !important;
    }

    &:has(.dx-state-focused) {
      border-color: $primary !important;
    }

    .d5-field {
      height: $height - $field-border-width * 2; //мінус бордери
      min-width: inherit;

      .dx-texteditor-container {
        height: 100%;
      }
    }
  }

  .clear-indicator {
    font-size: map.get($config, 'clear-indicator-size');
  }

  .d5-editor-button.dx-button.dx-button-has-text {
    padding: 0 4px;
  }
}

@each $size in table_vars.$row-sizes {
  .root-#{$size} {
    @include get-field-size($size);
    @include item-margin-class($size);
  }
}

@mixin range-slider-control($size) {
  $config: base_config();
  $main-value: map.get($config, 'font-size');

  #root {
    &.root-#{$size} {
      .d5-range-slider {
        .dx-popup-content {
          font-size: $main-value;
          margin-top: -($main-value * 2);
        }

        .dx-slider-handle {
          &:after {
            width: $main-value;
            height: $main-value;
            margin-top: -(math.div($main-value, 2));
            margin-left: -(math.div($main-value, 2));
          }
        }

        .dx-slider-label {
          font-size: $main-value;
        }
      }
    }
  }
}

@each $size in table_vars.$row-sizes {
  @include range-slider-control($size);
}
