@use 'scss/ui3/variables' as *;

@mixin tagboxStylingModes($applyToContainer) {

  //якщо $applyToContainer, то схеми проставляються на контейнер тагбокса і всі таги розмальовуються
  @if $applyToContainer {
    @include color-tag();
  }

  @else {
    //це блок для тагів в таблиці (не редагування)
    //якщо не $applyToContainer, то схеми проставляються в дів .multiselect-container

    // success

    &.color-scheme-success.styling-mode-contained {
      @include color-scheme-success-contained;
    }

    &.color-scheme-success.styling-mode-text {
      @include color-scheme-success-text;
    }

    &.color-scheme-success.styling-mode-outlined {
      @include color-scheme-success-outlined;
    }

    // default

    &.color-scheme-default.styling-mode-text {
      @include color-scheme-default-text;
    }

    &.color-scheme-default.styling-mode-outlined {
      @include color-scheme-default-outlined;
    }

    &.color-scheme-default.styling-mode-contained {
      @include color-scheme-default-contained
    }

    // primary

    &.color-scheme-primary.styling-mode-text {
      @include color-scheme-primary-text;
    }

    &.color-scheme-primary.styling-mode-outlined {
      @include color-scheme-primary-outlined;
    }

    &.color-scheme-primary.styling-mode-contained {
      @include color-scheme-primary-contained
    }

    // warning

    &.color-scheme-warning.styling-mode-text {
      @include color-scheme-warning-text;
    }

    &.color-scheme-warning.styling-mode-outlined {
      @include color-scheme-warning-outlined;
    }

    &.color-scheme-warning.styling-mode-contained {
      @include color-scheme-warning-contained;
    }

    // danger

    &.color-scheme-danger.styling-mode-text {
      @include color-scheme-danger-text;
    }

    &.color-scheme-danger.styling-mode-outlined {
      @include color-scheme-danger-outlined;
    }

    &.color-scheme-danger.styling-mode-contained {
      @include color-scheme-danger-contained;
    }


    // normal
    &.color-scheme-normal.styling-mode-text {
      @include color-scheme-normal-text;
    }

    &.color-scheme-normal.styling-mode-outlined {
      @include color-scheme-normal-outlined;
    }

    &.color-scheme-normal.styling-mode-contained {
      @include color-scheme-normal-contained;
    }

    // main-scheme
    &.color-scheme-main-scheme.styling-mode-text {
      @include color-scheme-main-text;
    }

    &.color-scheme-main-scheme.styling-mode-outlined {
      @include color-scheme-main-outlined;
    }

    &.color-scheme-main-scheme.styling-mode-contained {
      @include color-scheme-main-contained;
    }
  }


  .dx-tag>div,
  .dx-tag {
    @include color-tag();
  }
}

// в тагбокс і CustomTagBox
@mixin color-tag() {
  //success
  &.color-scheme-success.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-success-contained;
    }
  }

  &.color-scheme-success.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-success-text;
    }
  }

  &.color-scheme-success.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-success-outlined;
    }
  }

  //default
  &.color-scheme-default.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-default-outlined;
    }
  }

  &.color-scheme-default.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-default-outlined;
    }
  }

  &.color-scheme-default.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-default-contained;
    }
  }

  //primary
  &.color-scheme-primary.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-primary-outlined;
    }
  }

  &.color-scheme-primary.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-primary-outlined;
    }
  }

  &.color-scheme-primary.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-primary-contained;
    }
  }

  //warning
  &.color-scheme-warning.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-warning-text;
    }
  }

  &.color-scheme-warning.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-warning-outlined;
    }
  }

  &.color-scheme-warning.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-warning-contained;
    }
  }

  //danger
  &.color-scheme-danger.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-danger-text;
    }
  }

  &.color-scheme-danger.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-danger-outlined;
    }
  }

  &.color-scheme-danger.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-danger-contained;
    }
  }

  //normal
  &.color-scheme-normal.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-normal-text;
    }
  }

  &.color-scheme-normal.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-normal-outlined;
    }
  }

  &.color-scheme-normal.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-normal-contained;
    }
  }

  //main
  &.color-scheme-main-scheme.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-main-text;
    }
  }

  &.color-scheme-main-scheme.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-main-outlined;
    }
  }

  &.color-scheme-main-scheme.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-main-contained;
    }
  }
}

@mixin styling-mode-contained($bg, $color) {
  background-color: $bg;
  color: $color;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid $bg;
  @include delete-icon-color-scheme($color)
}

@mixin styling-mode-outlined($bg, $color, $border-color) {
  color: $color;
  background: $bg;
  border: 1px solid $border-color;
  @include delete-icon-color-scheme($color)
}

@mixin styling-mode-text($color) {
  background-color: transparent;
  border: 1px solid transparent;
  color: $color;
  @include delete-icon-color-scheme($color)
}

@mixin delete-icon-color-scheme($color) {
  .dx-tag-remove-button {
    &:before {
      color: $color;
    }
  }
}
//normal
@mixin color-scheme-normal-contained {
  @include styling-mode-contained($white, $black);
}

@mixin color-scheme-normal-outlined {
  @include styling-mode-outlined($white, $black, $dark-30);
}

@mixin color-scheme-normal-text {
  @include styling-mode-text($black);
}

//main
@mixin color-scheme-main-contained {
  @include styling-mode-contained($gray-2, $white);
}

@mixin color-scheme-main-outlined {
  @include styling-mode-outlined($white, $gray-2, $gray-2);
}

@mixin color-scheme-main-text {
  @include styling-mode-text($gray-2);
}

//danger
@mixin color-scheme-danger-contained {
  @include styling-mode-contained($error, $white);
}

@mixin color-scheme-danger-outlined {
  @include styling-mode-outlined($white, $error, $error);
}

@mixin color-scheme-danger-text {
  @include styling-mode-text($error);
}

//warning
@mixin color-scheme-warning-contained {
  @include styling-mode-contained($warn, $white);
}

@mixin color-scheme-warning-outlined {
  @include styling-mode-outlined($white, $warn, $warn);
}

@mixin color-scheme-warning-text {
  @include styling-mode-text($warn);
}

//default
@mixin color-scheme-default-contained {
  @include styling-mode-contained($white, $black);
}

@mixin color-scheme-default-outlined {
  @include styling-mode-outlined($white, $black, $dark-30);
}

@mixin color-scheme-default-text {
  @include styling-mode-text($black);
}

//primary
@mixin color-scheme-primary-contained {
  @include styling-mode-contained($secondary, $white);
}

@mixin color-scheme-primary-outlined {
  @include styling-mode-outlined($white, $secondary, $secondary);
}

@mixin color-scheme-primary-text {
  @include styling-mode-text($secondary);
}

//success
@mixin color-scheme-success-contained {
  @include styling-mode-contained($base-success, $white);
}

@mixin color-scheme-success-outlined {
  @include styling-mode-outlined($white, $base-success, $base-success);
}

@mixin color-scheme-success-text {
  @include styling-mode-text($base-success);
}
