@use "scss/ui3/buttons-color-scheme" as *;
@use "scss/ui3/variables" as *;

.styled-dropdownbutton {
  &.styling-mode-text, &.styling-mode-outlined {
    &.color-scheme-success {
      .dx-button {
        @include flat-dropdown-button($button-success-color);
      }
    }

    &.color-scheme-default {
      .dx-button {
        @include flat-dropdown-button($black);
      }
    }

    &.color-scheme-primary {
      .dx-button {
        @include flat-dropdown-button($primary);
      }
    }

    &.color-scheme-danger {
      .dx-button {
        @include flat-dropdown-button($button-danger-bg);
      }
    }

    &.color-scheme-warning {
      .dx-button {
        @include flat-dropdown-button($button-warning-color);
      }
    }

    &.color-scheme-main-scheme {
      .dx-button {
        @include flat-dropdown-button($button-main-scheme-color);
      }
    }
  }

  &.styling-mode-contained {
    &.color-scheme-success {
      .dx-button {
        @include contained-drop-down($button-success-color);
      }
    }

    &.color-scheme-default {
      .dx-button {
        @include contained-drop-down($black);
      }
    }

    &.color-scheme-primary {
      .dx-button {
        @include contained-drop-down($primary);
      }
    }

    &.color-scheme-danger {
      .dx-button {
        @include contained-drop-down($button-danger-bg);
      }
    }

    &.color-scheme-warning {
      .dx-button {
        @include contained-drop-down($button-warning-color);
      }
    }

    &.color-scheme-main-scheme {
      .dx-button {
        @include contained-drop-down($button-main-scheme-color);
      }
    }
  }
}
