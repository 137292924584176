@use "variables" as *;
@use "mixins" as *;
@use 'scss/ui3/tables/variables' as table_vars;
@use "../variables"  as toolbar_vars;
@use "scss/ui3/configs/context-menu"  as *;
@use "table-toolbar-mixin"  as *;
@use 'sass:map';

$toolbar-margin-top: 3px;
$toolbar-margin-bottom: 8px;

.horizontal-separator {
  width: 2px;
  height: 30px;
  background: $gray;
  position: relative;

  &_dot {
    background: transparent;
    position: relative;
    width: 2px;
    margin-right: 12px;
    margin-left: 0;

    &::before {
      content: "";
      background: $gray;
      width: 2px;
      height: 12px;
      background: #D9D9D9;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.table-toolbar-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  .toolbar-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .d5-table-toolbar {
      flex: 1;
      white-space: nowrap;
    }
  }

  .toolbar-container-for-others {
    display: flex;
    flex-wrap: nowrap;
    flex: 0 0 auto;
    margin-top: $toolbar-margin-top;
    margin-bottom: $toolbar-margin-bottom;
  }

  .dx-button-content {
    display: flex;
    align-items: center;
    padding: 2px;

    .dx-icon {
      padding: 0 !important;
      margin-right: 0 !important;
    }

    & div:first-child {
      display: inline-flex;
      align-items: center;

      & .dx-icon:not(img) {
        align-items: center;
        height: 100% !important;
        width: auto;
      }

      & .dx-button-text {
        padding-left: 4px;
      }
    }
  }

  .d5-table-toolbar {
    display: flex;
    justify-content: space-between;
    margin-top: $toolbar-margin-top;
    margin-bottom: $toolbar-margin-bottom;

    .toolbar-left-part,
    .toolbar-right-part{
      display: flex;
      column-gap: 8px;
    }

    &.with-buttons{
      // Фіксуємо мінімальну висоту, бо при рендері разом з таблицями , таблиці можуть не вірно розраховувати висоту
      // і може з'являтись скрол там де він не потрібен
      min-height: toolbar_vars.$toolbar-min-height-desktop;
    }
  }

  .toolbar-item {
    display: flex;

    &:last-of-type {
      margin-right: 0;
    }

    &.section-item {
      border: $dark-30 1px solid;
      border-left-width: 0;
      margin-left: -4px;
      margin-right: -4px;
    }

    &.section-item-first {
      border-left-width: 1px;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      margin-left: 2px;
    }

    &.section-item-last {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      margin-right: 2px;
    }

    .dx-dropdownbutton-action, .dx-dropdownbutton-toggle {
      height: 24px;
    }
  }

  .horizontal-separator {
    margin-right: 6px;
    margin-left: 5px;
  }

  .d5-is-pressed {
    i, span {
      color: $primary !important;
    }

    path {
      fill: $primary;
    }
  }

  .d5-react-svg-icon > div {
    width: 16px;
  }

  .dx-dropdownbutton {
    .d5-react-svg-icon > div svg {
      max-width: 16px;
      max-height: 16px;
    }
  }

  .toolbar-button, .toolbar-group-button, .dx-dropdownmenu-button {
    min-width: 24px;
    height: 24px;
    border-radius: 2px !important;

    & .dx-button-text {
      padding-left: 4px;
      padding-right: 2px;
      max-width: 100px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .dx-dropdownmenu-button {
    .dx-icon {
      transform: rotate(90deg);
    }
  }

  .dx-item[aria-label='far fa-filter'] {
    .dx-button-content {
      padding-right: 0;
      padding-left: 5px;
    }

    + .dx-item {
      .dx-icon {
        margin-right: 2px;
      }
    }
  }

  .dx-button-has-text {
    .dx-button-content {
      padding: 4px;
    }
  }

  .toolbar-group-button {
    & .dx-buttongroup-wrapper .dx-button-content {
      padding: 0 2px 0 4px;

      .dx-icon-spindown {
        margin-inline-start: -1px;

        &::before {
          border-style: solid;
          border-width: 1.7px 1.7px 0 0;
          content: '';
          display: inline-block;
          height: 6.5px;
          left: 1px;
          position: relative;
          vertical-align: top;
          width: 6.5px;
          top: -1.5px;
          transform: rotate(135deg);
        }
      }
    }

    //если кнопка сплитнутая например кнопка отображения фильтра, то в ней рисуются
    //вложенные две кнопки вместо двух иконок
    &.split-drop-down-btn {
      //action button
      .dx-dropdownbutton-action {
        .dx-button-content {
          padding: 0 2px 0 2px;
        }
      }
      //arrow down
      .dx-dropdownbutton-toggle {
        .dx-button-content {
          padding: 0;
        }
      }
    }

  }

  .dx-buttongroup-wrapper:hover .dx-widget:hover.dx-button .dx-button-content {
    background-color: $toolbar-btn-hover-bg;
  }

  .dx-buttongroup-wrapper .dx-widget:hover.dx-button.dx-dropdownbutton-toggle .dx-button-content {
    color: $toolbar-icon-hover-color;

    .dx-icon-spindown {
      &:before {
        color: $toolbar-icon-hover-color;
      }
    }
  }

  .d5-is-pressed {
    .dx-buttongroup-wrapper .dx-widget.dx-button.dx-dropdownbutton-toggle {
      background-color: $white;
    }
  }


  .dx-toolbar-items-container {
    height: $table-toolbar-height;
  }
}


.dx-multiview-items-container {
  flex: 1;
  overflow: hidden;
}

.dx-dropdownmenu-popup-wrapper {
  & > div {
    border-radius: 5px !important;
  }
}

//меню в тулбаре там где три точки
.dropdown-list-wrapper {
  @each $size in table_vars.$row-sizes {
    $config: cm_config();
    &.row-size-#{$size} {
      //каждый элемент списка должен быть подчеркнут
      & > .dx-list-item {
        border-bottom: 1px solid rgba(102, 102, 102, 0.08);

        &:last-child {
          border-bottom: none;
        }

        &.dx-state-disabled {
          opacity: 1 !important;
        }
      }

      .hidden-menu-button, .toolbar-button {
        width: 100%;
        height: map.get($config, 'height') !important;

        .disabled {
          opacity: 1 !important;
        }

        .dx-button-content {
          padding: 0 12px 0 8px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }

      //все иконки
      .dx-icon, i, svg {
        @include toolbar-icon();
      }

      //весь текст
      span {
        @include toolbar-hidden-menu-text($config);
      }

      .dx-button-content {
        & div:first-child {
          display: flex;
          align-items: center;

          & .dx-button-text {
            padding-left: 4px;
          }
        }
      }

      .hidden-menu-button {
        .toolbar-group-button {
          padding: 0;

          & i {
            color: rgb(110, 116, 146);
          }
        }

        .dx-button-content {
          padding: 0 12px !important;
        }
      }
    }
  }
}

@each $size in table_vars.$row-sizes {
  #root.root-#{$size} {

    $config: get_title_config($size);
    $config: get_icon_config();

    .table-toolbar-wrapper {
      .d5-table-toolbar {
        .toolbar-button, .toolbar-group-button, .dx-dropdownmenu-button {
          height: map.get($config, 'table-toolbar-height');
          min-width: map.get($config, 'table-toolbar-height');
        }
      }
    }

    .mobile-form-toolbar-wrapper {
      .toolbar-item {
        .dx-button.d5-is-pressed {
          .dx-icon > svg {
            color: $white !important;
          }
        }

        .dx-button {
          .dx-icon > svg, i {
            font-size: 18px;
            height: 20px;
            width: 20px;
          }
        }
      }

      .toolbar-item:not(.disabled) {
        .dx-button:not(.d5-is-pressed) {
          .dx-icon > svg, i:not(.dx-icon-spindown) {
            color: $primary !important;
            path {
              fill: $primary !important;
            }
          }
        }
      }
    }

    :not(.mobile-form-toolbar-wrapper) {
      @include toolbar-button($toolbar-icon-size);
    }
  }
}
