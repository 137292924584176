@use "_variables" as *;
@use "scss/ui3/variables" as *;
@use "../../prepend-slash" as *;
@use 'sass:map';

//$new-height = 30
@mixin table-header-cell-size($config) {
  $font-size: map.get($config, 'font-size');
  $line-height: map.get($config, 'line-height');
  $padding: map.get($config, 'padding');
  $height: map.get($config, 'height');
  $top: map.get($config, 'top');

  font-size: $font-size;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  //высота врапера с иконками сортировки и фильтра
  .dx-column-indicators {
    height: $height;

    //иконками сортировки и фильтра
    .dx-header-filter, .dx-sort {
      $size: $line-height;
      width: $size;
      height: $size;
      background-size: $size;
      font-size: $size;
      line-height: $size;
    }

    .dx-header-filter {
      width: 16px;
    }

    .dx-sort {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .drag-icon {
    height: $height;
    line-height: $height;
    &:before {
      padding: $padding 1px;
    }
  }

  //паддинги текста будут задавать выоту ячейки, так как у иконок будет свой паддинг для увеличения кликабельной зоны
  .dx-datagrid-text-content, .dx-treelist-text-content {
    padding-top: $padding;
    padding-bottom: $padding;
    //добавлено для симетрии и компенсации правого паддинга (до 11px), если прячутся иконки фильтра и сортировки
    padding-right: 5px;
    //обнуление марджина от DX , потому что соседние элементы находятся на большой дистанции
    margin-right: 0;
  }

  .column-required-icon-space {
    line-height: 20px;
  }
}


@mixin table-data-cell-size($config) {
  $font-size: map.get($config, 'font-size');
  $height: map.get($config, 'height');
  $line-height: map.get($config, 'line-height');
  $padding: map.get($config, 'padding');
  $checkbox-padding: map.get($config, 'checkbox-padding');

  font-size: $font-size;
  line-height: $line-height;
  padding: $padding $padding + 1 $padding - 3;
  //додано для того що б не стискався checkbox при вертикальному розміщені заголовка і мінімальній ширині колонки
  &:has(.svg-boolean-column-checkbox) {
    padding: $checkbox-padding;
  }
}

@mixin table-editing-cell-size($config) {

  $font-size: map.get($config, 'font-size');
  $height: $field-inline-height;

  $line-height: map.get($config, 'line-height');
  $input-height: map.get($config, 'input-height');
  $input-inline-height: map.get($config, 'inline-height');

  height: $height;

  .dx-texteditor-input {
    font-size: $font-size;
    line-height: 100%;
  }

  .dx-texteditor-input:not([aria-multiline="true"]) {
    height: $input-inline-height !important;
  }
}

// стиль иконки узлов дерева
@mixin tree-list-icon {
  height: 12px;
  width: 8px;
  font-size: 16px;
  margin: 0;
  @include fa-icon();
  font-weight: 900;
  line-height: 100%;
  //иконка caret right font awesome (стрелочка вправо)
  content: prepend-slash("f0da");
  top: 50%;
  color: $gray-2;
}

@mixin tree-list-arrow-columns {
  .dx-treelist-rowsview {
    // переопределяются стили иконки узлов дерева
    .dx-treelist-icon-container {
      .dx-treelist-collapsed {
        span:before {
          @include tree-list-icon;
          left: 0;
          transform: translateY(-50%);
        }
      }

      .dx-treelist-expanded {
        span:before {
          @include tree-list-icon;
          left: 3px;
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
  }
}

@mixin select-dropdown-button-base-styles($button-config) {
  min-width: map.get($button-config, 'width');
  height: map.get($button-config, 'height');
}