@use "sass:math";
@use "scss/ui3/variables" as *;

// Размеры свитча для таблицы и дерева
$table-switch-width: 28px;
$table-switch-height: 28px;
$table-switch-handle-size: 10px;

/*
// Размеры свитча как в дизайне
$table-switch-width: 20px;
$table-switch-height: 12px;
$table-switch-handle-size: 8px;
*/

// цвет круглого ползунка
$switch-on-handle-color: #fff;
// цвет включенного состояния
$switch-on-track-color: #00D058;
// цвет выключенного состояния
$switch-off-track-color: $gray-2;
// прозрачность неактивного свитча
$switch-off-opacity: .5;

$switch-control-width: 32px;
$switch-control-height: 32px;
$switch-control-handle-size: 12px;
$switch-control-switch-margin: 0;

@mixin switch-style($bg-color, $handle-color) {
  .dx-switch-container {
    &:before {
      background-color: $bg-color;
    }

    .dx-switch-inner {
      .dx-switch-handle {
        box-shadow: 0 0 0 1.5px $bg-color;

        &:before {
          background-color: $handle-color !important;
        }
      }
    }
  }
  &.dx-state-focused .dx-switch-handle::before {
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.1);
  }

}

@mixin switch-size($height, $width, $handle-size, $margin) {
  width: $width !important;
  height: $height;

  .dx-switch-container {
    width: $width - 4px;
    height: math.div($height, 2);

    &:before {
      height: math.ceil(math.div($height, 2.1));
      margin: $margin;
      @-moz-document url-prefix() {
        height: math.ceil(math.div($height, 2.1))+0.5px;
      }
    }

    .dx-switch-inner {
      display: flex;
      align-items: center;

      .dx-switch-handle {
        width: $handle-size + 2;
        height: $handle-size + 2;
        flex-basis: $handle-size + 2;
      }
    }
  }
}

.dx-switch {
  /* Общий стиль для неактивных(readonly) свитчей*/
  &.dx-state-readonly {
    opacity: $switch-off-opacity;
  }

  .dx-switch-wrapper {
    box-sizing: content-box !important;
    left: -8px;
    padding: 10px 13px;
  }

  @include switch-style($switch-off-track-color, $switch-on-handle-color);

  &.dx-state-readonly {
    opacity: $switch-off-opacity;

    @include switch-style($switch-off-track-color, $dark-gray-readOnly);
  }

  /* Общий стиль для включенного свитча */
  &.dx-switch-on-value {
    @include switch-style($switch-on-track-color, $switch-on-handle-color);
  }
}

.switch-control-wrapper {
  width: $switch-control-width + 10; //додаємо 10 щоб тінь не обрізав wrapper
  display: flex;
  overflow: hidden;
  outline: none;
  padding: 0 2px;

  .d5-field {
    min-width: $switch-control-width + 10;
  }

  .dx-switch .dx-switch-wrapper {
    width: auto !important;
  }
}

/* Стили свитча в фильтрах и форме редактирования BoolControl.jsx */
.switch-control {
  @include switch-size($switch-control-height, $switch-control-width, $switch-control-handle-size, 0);
}

.table-wrapper .dx-treelist, .table-wrapper .dx-datagrid {
  .dx-switch {
    @include switch-size($table-switch-height, $table-switch-width, $table-switch-handle-size, $switch-control-switch-margin);
  }
}
