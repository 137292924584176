@use "scss/ui3/variables" as *;

$selected-children-bg: $gray-6;
$root-tr-border-color: #C7EAFF;
$selected-rows-border-color: $gray-2;

//стили только для трилиста
.table-wrapper {
  .dx-treelist {
    tr {
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
    }

    .marked-root-node {
      //для раскрытых нод первого уровня рисуем бордер сверху
      &[aria-expanded='true'] td {
        border-bottom: none;
      }

      //только для нод первого уровня нужно сделать шрифт жирным
      td:not(.dx-editor-cell), {
        font-weight: bold !important;
      }
    }

    //у всех дочерних строк бг - голубой, при чем у рутовых белый
    .marked-node {
      background: $selected-children-bg;

      td {
        background: $selected-children-bg;
      }
    }

    //у всех раскрытых эл. бордер слева

    .marked-root-node[aria-expanded='true'],
    .marked-node   {
      border-left: 2px solid $selected-rows-border-color;
    }

    //уменьшить отступ от стрелки, так же управляет отступом перед стрелкой во вложенных нодах
    .dx-treelist-empty-space {
      width: 13px;
    }
  }
}

