@use "variables" as *;
@use "tables/mixins" as *;
@use "configs/column-settings" as *;
@use 'scss/ui3/tables/variables' as table_vars;
@use 'sass:map';

.dx-overlay-content {

  .link-buttons-wrapper + div[class^="styles_mainContent"] {
    margin-left: -25px;
    margin-right: -18px;

    .dx-command-drag {
      &.custom-setting-drag {
        &.dx-treelist-cell-expandable {
          height: 26px;
        }
      }
    }

    .dx-command-drag {
      .dx-treelist-drag-icon {
        &:before {
          float: right;
          margin-right: -3px;
        }
      }
    }
  }
}

.filter-settings {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  .filter-settings__content {
    .dx-treelist-headers .dx-treelist-content {
      height: 0;
    }
  }

  .column-settings__top-buttons {
    .link-buttons-wrapper {
      padding: 6px 0 6px 4px;
      flex: 0 0 auto;
      margin: 0 !important;
    }

    .link-buttons-column-settings {
      position: relative;
    }
  }

  .column-settings__tree {
    margin-top: 0;

    .dx-data-row td {
      padding: 3px 0 !important;
    }
  }

  .dx-button-text {
    text-transform: capitalize;
    font-size: 0.75rem;
  }

  &__content {
    overflow: hidden;
  }
  &__toggle-button {
    flex: 0 0 auto;
    .dx-button-content {
      .dx-icon {
        color: $gray-2;
      }
    }
    .dx-button-text {
      float: left;
      color: $gray-2;
    }
  }

  &__hide {
    .dx-button-content{
      display: flex;
      align-items: center;
      margin-top: 2px;
      .dx-icon{
        float: left;
        color: $gray-2;
        margin-right: auto;
      }
    }
  }

  &__show {
    .dx-button-content{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      .dx-icon {
        float: right;
        color: $gray-2;
        margin-left: auto;
      }
    }

  }

  &__filter-item {
    white-space: nowrap;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .caption-star-container {
    width: 10px;
    position: relative;
    margin-right: 5px;

    .caption-star {
      display: block;
      position: absolute;
      top: -2px;
      left: 2px;
    }
  }

  &__filter-checkbox {
    margin-left: 15px;
  }

  &__item-list {
    padding-right: 10px;
  }
}

@each $size in table_vars.$row-sizes {
  #root.root-#{$size} .filter-settings {
    $config: column-settings_config($size);
    .caption-container {
      .caption {
        font-size: map.get($config, 'row-font-size');
      }
    }

    .column-settings__tree .dx-data-row td .dx-treelist-text-content {
      font-weight: normal;
    }
  }
}
