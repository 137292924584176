@charset "UTF-8";
@use "sass:string" as string;

@function prepend-slash($fa-var) {
  //@return unquote('"\\#{$value}"');
  //@return unquote("\"#{ $fa-var }\"");
  @return string.unquote("\"")+string.unquote(string.insert($fa-var, "\\", 1))+string.unquote("\"");
}

@mixin fa-icon {
  font-family: 'Font Awesome 6 Pro', serif !important;
}

@mixin fa-icon-solid() {
  font: var(--fa-font-solid);;
}
