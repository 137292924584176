@use "scss/ui3/variables" as *;
@use 'scss/modes' as *;

.dx-overlay-wrapper {

  &.operation-popup {
    .dx-overlay-content {
      overflow: hidden;
    }
  }

  &.dx-dropdownlist-popup-wrapper {
    .dx-overlay-content {
      min-width: 200px !important;

      .dx-popup-content {
        padding: 0 !important;

        .dx-item-content {
          padding-left: 12px;
          padding-right: 12px;
        }

        .dx-list-item:first-of-type, .dx-list-item:last-of-type {
          margin: 0;
        }

        .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover:not(.dx-state-focused) {
          background-color: #F3F5FA;
          color: #36445E;
        }

        .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
          background-color: #D4D7E4 !important;
          color: #36445E;
        }
      }
    }
  }
  .dx-overlay-content {
    border-radius: 5px;

    // base popup padding
    .dx-popup-content:not(.d5-hint) {
      padding-bottom: 5px;
      padding-right: 10px;
      padding-left: 10px;
    }

    .dx-popup-content:not(.d5-hint):not(.dx-diagram-toolbox-popup *) {
      padding: 5px 10px;
    }

    .dx-popup-content:not(.d5-hint) {
      background: #fff;

      .dx-item-content {
        font-size: $medium-font-size;
      }
    }

    .dx-list-items .dx-list-item {
      &.dx-state-focused {
        .dx-checkbox-icon {
          border-color: $focused-color;
        }
      }

      &[aria-selected="true"] {
        &.dx-state-focused {
          .dx-checkbox-icon {
            border: 2px solid $focused-color;
          }
        }
      }
    }

    @media (max-width: 480px) {
      .dx-toolbar .dx-toolbar-items-container .dx-button-content {
        padding: 5px; // в popup datatime кнопки в toolbar злипаються
      }
    }
  }
  &.desktop-button-settings {
    .dx-overlay-content {
      .dx-popup-content:not(.d5-hint) {
        padding: 16px;
        border-radius: 8px;
      }
    }
  }

  &.dx-diagram-toolbox-popup {
    z-index: 998 !important;
  }
}
