@use "variables" as *;

.accent-bg {
  background-color: $gray-2;
}

.secondary-bg {
  background-color: $dark;
}

.primary-text-color {
  color: $dark;
}

.button-icon-color {
  color: $gray-2 !important;
}
.label-text-color{
  color: $gray-2
}

.button-accent-icon-color {
  color: $gray-2 !important;
}
