@use "scss/ui3/buttons-color-scheme" as *;
@use "scss/ui3/variables" as *;

.styled-button {
  &.styling-mode-outlined {
    border: 1px solid rgba(0, 0, 0, 0.24);
  }

  &.styling-mode-text:not(.dx-state-hover, .dx-state-focused) {
    background: 0 0;
  }

  &.styling-mode-text, &.styling-mode-outlined {
    box-shadow: none;

    &.color-scheme-success {
      @include flat-dropdown-button($button-success-color);
    }

    &.color-scheme-default {
      @include flat-dropdown-button($black);
    }

    &.color-scheme-primary {
      @include flat-dropdown-button($primary);
    }

    &.color-scheme-danger {
      @include flat-dropdown-button($button-danger-bg);
    }

    &.color-scheme-warning {
      @include flat-dropdown-button($button-warning-color);
    }

    &.color-scheme-main-scheme {
      @include flat-dropdown-button($button-main-scheme-color);
    }
  }

  &.styling-mode-contained {
    &.color-scheme-success {
      @include contained-drop-down($button-success-color);
    }

    &.color-scheme-default {
      @include contained-drop-down($black);
    }

    &.color-scheme-primary {
      @include contained-drop-down($primary);
    }

    &.color-scheme-danger {
      @include contained-drop-down($button-danger-bg);
    }

    &.color-scheme-warning {
      @include contained-drop-down($button-warning-color);
    }

    &.color-scheme-main-scheme {
      @include contained-drop-down($button-main-scheme-color);
    }
  }
}
