@use "scss/ui3/variables" as *;

.page404-wrapper {
  color: #202A3C;
  display: flex;
  flex: 1 0 auto;

  .image-container {
    position: relative;

    .image {
      background-image: url("../../assets/img/page-404-bg.svg");
      background-repeat: no-repeat;
      width: 450px;
      height: 340px;

      background-size: 450px 340px;
    }

    .page-not-found-text__container {
      position: absolute;
      right: 50%;
      bottom: 0;
      transform: translate(49%, -95%);

      .page-not-found-text {
        white-space: nowrap;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #6E7492;
      }
    }
  }

  .return-to-homepage {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1453F8;
    text-decoration: underline;

    a:visited {
      color: #1453F8;
    }
  }

  .logo-container {
    margin-top: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-page-logo {
    --logo-svg-stop1-color: #36445E;
    --logo-svg-stop2-color: #36445E;
    opacity: 1;

    color: #36445E;
    $size: 30px;
    width: 100%;
    height: 100%;
    max-width: $size;
    max-height: $size;
  }

  .logo-text {
    color: #36445E;
    margin-left: 15px;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
  }

  // mobile version
  &.mobile {
    padding: 0;

    .logo-container {
      margin-top: 120px;
    }

    & .container {
      padding: 0;

      & .image-container {
        .image {
          width: 350px;
          height: 270px;

          background-size: 350px 270px;
        }

        .page-not-found-text {
          font-size: 19px;
          line-height: 26px;
        }
      }
    }

    @media (orientation: landscape) {
      .container {
        & .image-container {
          .image {
            width: 250px;
            height: 180px;

            background-size: 250px 180px;
          }

          .page-not-found-text__container {
            transform: translate(49%, -40%);

            .page-not-found-text {
              font-size: 14px;
            }
          }
        }
      }

      .logo-container {
        margin-top: 40px;
      }
    }
  }
}
