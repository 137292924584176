@use "scss/ui3/variables" as *;

.dx-toast-info.dx-toast-content {
  padding: 0;
  background-color: #FFFFFF;
  box-shadow: $notification-shadow;
}

.snackbar {
  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    height: 100%;
    color: $dark;
    overflow-wrap: anywhere;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__message {
    white-space: pre-line;
    font-size: 12px;
    line-height: 20px;
    color: $dark;
    margin: 8px 0 0 0;

    &__has-not-title {
      margin-top: 0 !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__detailed-info {
    margin-top: 20px;
    color: $dark;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: end;

      .error-info {
        flex: 1 0 auto;
        width: 100%;
        overflow: auto;
        padding: 10px;
        margin-top: 5px;
        border: 1px solid $lightBorderPanelColor;
      }
    }

    .dx-state-focused, .dx-state-hover {
      background-color: transparent;
    }

    .dx-button {
      color: $primary;
      font-size: 12px;
      min-width: unset;

      .dx-button-content {
        padding: 5px 0 !important;
      }
    }
  }
}

[data-type="help"] {
  .snackbar {
    &__title {
      margin-bottom: 20px;
    }

    &__message {
      margin-bottom: 20px;
    }

    &__link {
      font-style: normal;
      font-weight: 400;

      & a {
        text-decoration: none;
      }
    }
  }
}
